import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { navigate } from "gatsby"
import React, { useState } from 'react';
import HordeManager from '../components/HordeAccount'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Router } from "@reach/router"
import Description from '../components/Products'
import text from '../data/tos.js'

const ProductsPage = ({ location }) => {

  const handleBack = () => {
    navigate("/")
  }

  const pageTitle = "Horde TOS"

  const pageDesc = "TOS"

  return (
  <Layout pageTitle={pageTitle}>
    <SEO desc={pageDesc} title={pageTitle} />
      <IconButton
      onClick={handleBack}>
        <ArrowBackIcon/>
      </IconButton>
      <div dangerouslySetInnerHTML={{__html:text}}></div>
  </Layout>
)}

export default ProductsPage
